@import "https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap";
@font-face {
}

@font-face {
  font-family: Atlan;
  src: url("atlan-heavy.d59b6830.otf") format("opentype");
}

.atlan {
  font-family: Atlan, sans-serif;
}

.rubik {
  font-family: Rubik, sans-serif;
}

#app * {
  text-decoration: none;
}

body {
  background-color: #000;
  width: 100vw;
  height: 100vh;
  margin: 0;
  overflow: hidden;
}

#app {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  display: flex;
  overflow: hidden;
}

#login-form, #register-form {
  text-align: center;
  flex-direction: column;
  place-content: center;
  align-items: center;
  width: 350px;
  margin: 0 auto;
  display: flex;
}

.input-field {
  background-color: #ececec;
  border: none;
  border-radius: 5px;
  width: 100%;
  height: 18px;
  margin-bottom: 2rem;
  padding: 17px;
  font-family: Rubik, sans-serif;
  font-size: 18px;
  font-weight: 600;
}

.input-button {
  cursor: pointer;
  background-color: #ececec;
  border: none;
  border-radius: 5px;
  width: 6rem;
  height: 4rem;
  margin-top: 10px;
  font-family: Rubik, sans-serif;
  font-size: 16px;
  transition: all .3s ease-in-out;
}

.input-button:hover {
  background-color: #beb6b6;
}

.message-container {
  min-height: 5.5rem;
}

.error-message, .success-message {
  color: red;
  text-align: center;
  margin-bottom: 3px;
  padding: 5px;
  font-family: Rubik, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
}

.success-message {
  color: #00f;
}

.RegisterSesion {
  margin-top: 3rem;
}

.RegisterSesion p, .LoginSesion p {
  color: #878686;
  border-radius: 5px;
  font-family: Rubik, sans-serif;
  font-size: 20px;
  font-weight: 600;
}

.RegisterSesion a {
  color: #000;
}

.RegisterSesion p:nth-child(2), .LoginSesion p:nth-child(2) {
  color: #000;
  cursor: pointer;
}
/*# sourceMappingURL=index.f1f19d72.css.map */
