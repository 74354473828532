@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');



@font-face {
    /* font-family: 'Atlan';
    src: url('../fonts/atlan-bold.otf') format('opentype');
    font-style: bold;
    font-weight: bold; */
}

@font-face {
    font-family: 'Atlan';
    src: url('../fonts/atlan-heavy.otf') format('opentype');
}

.atlan{
    font-family: 'Atlan', sans-serif;
}

.rubik {
    font-family: "Rubik", sans-serif;
}

#app *{
    text-decoration: none;
}

/* #game-container {
    width: 100%;
    height: 100vh; 
    margin: 0;
    padding: 0;
} */

body {
    margin: 0px;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    background-color: #000000;
}

#app {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

#login-form, #register-form {
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    align-content: center;
    width: 350px;
    margin: 0 auto;
}

.input-field {
    width: 100%;
    height: 18px;
    border: none;
    border-radius: 5px;
    background-color: #ECECEC;
    font-family: 'Rubik', sans-serif;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 2rem;
    padding: 17px;
}

.input-button {
    border: none;
    border-radius: 5px;
    background-color: #ECECEC;
    font-family: 'Rubik', sans-serif;
    font-size: 16px;
    width: 6rem;
    height: 4rem;
    margin-top: 10px;
    cursor: pointer;
    transition: all 0.3s ease-in-out ;
}

.input-button:hover {
    background-color: #beb6b6;
}

.message-container {
    min-height: 5.5rem;
}

.error-message, .success-message {
    color: red;
    font-family: "Rubik", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 20px;
    text-align: center;
    padding: 5px; 
    margin-bottom: 3px;
}

.success-message {
    color: blue;
}

.RegisterSesion{
    margin-top: 3rem;
}

.RegisterSesion p, .LoginSesion p {
    border-radius: 5px;
    font-family: 'Rubik', sans-serif;
    font-size: 20px;
    font-weight: 600;
    color: #878686;
}

.RegisterSesion a{
    color:black;
}

.RegisterSesion p:nth-child(2),.LoginSesion p:nth-child(2){
    color: black;
    cursor: pointer;
}